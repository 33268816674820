<template>
  <div>
    <!--begin::Notice-->
    <div
      class="alert alert-custom alert-white alert-shadow gutter-b"
      role="alert"
    >
      <div class="alert-icon alert-icon-top">
        <span class="svg-icon svg-icon-3x svg-icon-primary mt-4">
          <!--begin::Svg Icon-->
          <inline-svg src="media/svg/icons/Communication/Share.svg" />
          <!--end::Svg Icon-->
        </span>
      </div>
      <div class="alert-text">
        <p>
          Введите локации, где проводятся тестирования вашей организации.
          Например, склад, офис, конкретное помещение и т.п.
          <br />Данные из этого раздела затем доступны для выбора при создании
          нового теста.
        </p>
      </div>
    </div>
    <!--end::Notice-->

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Локации</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center">
                <span data-toggle="tooltip" class="example-toggle"></span>
                <span data-toggle="tooltip" class="example-copy"></span>
              </div>
            </div>
          </div>
          <div class="card-body" data-app>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-data-table
                :headers="headers"
                :items="locations"
                :search="search"
                sort-by="id"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-text-field
                      v-model="search"
                      append-icon="search"
                      label="Поиск"
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          Добавить локацию
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="4" sm="3" md="2">
                                <v-text-field
                                  v-model="editedItem.id"
                                  label="ID"
                                  readonly
                                ></v-text-field>
                              </v-col>
                              <v-col cols="8" sm="9" md="10">
                                <v-text-field
                                  v-model="editedItem.LocationName"
                                  label="Название"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" rows="3" sm="12" md="12">
                                <v-textarea
                                  v-model="editedItem.LocationDescription"
                                  label="Описание"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            Отменить
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            Сохранить
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                      <v-card>
                        <v-card-title class="headline"
                          >Вы уверены, что хотите удалить локацию?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeDelete"
                            >Отмена</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItemConfirm"
                            >Да</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                  <v-icon small @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" @click="initialize">
                    Сбросить
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "org-locations",
  components: {},
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Название",
          sortable: true,
          value: "LocationName"
        },
        {
          text: "Описание",
          sortable: true,
          value: "LocationDescription"
        },
        {
          text: "Действия",
          value: "actions",
          sortable: false
        }
      ],
      locations: [],
      editedIndex: -1,
      editedItem: {
        id: "",
        LocationName: "",
        LocationDescription: ""
      },
      defaultItem: {
        id: "",
        LocationName: "",
        LocationDescription: ""
      }
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },

    formTitle() {
      return this.editedIndex === -1 ? "Новая локация" : "Редактировать";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "База данных" },
      { title: "Локации" }
    ]);

    this.$nextTick(() => {
      const hljs = this.$el.querySelectorAll(".hljs");
      hljs.forEach(hl => {
        hl.classList.remove("hljs");
        hl.classList.add(`language-${hl.classList[1]}`);
      });
    });
  },
  destroyed() {},
  methods: {
    initialize() {
      return new Promise(resolve => {
        ApiService.get("api/v1/location").then(({ data }) => {
          this.locations = data.results;
          this.editedItem.id = this.getLastId();
          resolve(data);
        });
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    getLastId() {
      if (this.locations.length) {
        return Math.max(...this.locations.map(el => el.id)) + 1;
      } else {
        return 1;
      }
    },

    editItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.locations.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.locations.splice(this.editedIndex, 1);
      return new Promise(resolve => {
        ApiService.delete("api/v1/location/" + this.editedItem.id + "/").then(
          ({ data }) => {
            this.closeDelete();
            resolve(data);
          }
        );
      }).catch(({ response }) => {
        console.error(response);
      });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.defaultItem.id = this.getLastId();
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        return new Promise(resolve => {
          ApiService.put("api/v1/location/" + this.editedItem.id + "/", {
            LocationName: this.editedItem.LocationName,
            LocationDescription: this.editedItem.LocationDescription,
            org_id: this.$store.getters.currentUser.org.id,
            Parent_id: ""
          }).then(({ data }) => {
            Object.assign(this.locations[this.editedIndex], this.editedItem);
            this.close();
            resolve(data);
          });
        }).catch(({ response }) => {
          console.error(response);
        });
      } else {
        return new Promise(resolve => {
          ApiService.post("api/v1/location/", {
            LocationName: this.editedItem.LocationName,
            LocationDescription: this.editedItem.LocationDescription,
            org_id: this.$store.getters.currentUser.org.id,
            Parent_id: ""
          }).then(({ data }) => {
            this.locations.push(data);
            this.close();
            resolve(data);
          });
        }).catch(({ response }) => {
          console.error(response);
        });
      }
    }
  }
};
</script>
